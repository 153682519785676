import './index.scss'
import fullpage from 'fullpage.js'
import 'fullpage.js/dist/fullpage.min.css'
import Swiper from 'swiper/dist/js/swiper.min.js'
import '../../assets/scripts/jquery.waypoints.min.js'
import '../../assets/scripts/jquery.countup.min.js'

(function ($) {
  browserRedirect()
  // 判断浏览器再哪打开
  function browserRedirect() {

    var sUserAgent = navigator.userAgent.toLowerCase();
    
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      console.log('移动');
      } else { 
        console.log('屏幕高度',window.outerHeight);
        if(window.outerHeight<=800){
          $('.section1 .cont .right-swiper .swiper .swiper-slide .item').css({'padding': '50px 40px 10px'})
          $('.section1 .cont .right-swiper .swiper .swiper-slide .item .item-img').css({'margin-bottom': '45px'})
        }
      }
    }
  $.fn.numberRock = function (options) {
    var defaults = {
      lastNumber: 100,
      duration: 3000,
      easing: 'swing' 
    };
    var opts = $.extend({}, defaults, options);

    $(this).animate({
      num: "numberRock",
    }, {
      duration: opts.duration,
      easing: opts.easing,
      complete: function () {
        // console.log("success");
      },
      step: function (a, b) {
        $(this).html(parseInt(b.pos * opts.lastNumber));
      }
    });
  }
})(jQuery);

//首页全屏滚动
var indexfullpage;
function initFullpage() {
  indexfullpage = new fullpage('#fullpage', {
    scrollingSpeed: 600,
    verticalCentered:true,
    css3: true,
    resize:true,
    autoScrolling: true,
    scrollBar: false,
    anchors: ['page1', 'page2', 'page3', 'page4','page5','page6','page7','page8'],
    afterLoad: function (origin, destination, direction, trigger) {
      // console.log('afterLoad', origin.index, destination.index, direction, trigger)
    },
    onLeave: function (origin, destination, direction,trigger) {
      // console.log('onLeave', origin.index, destination.index, direction,trigger)
      let index = destination.index
      console.log('1111',index);
      if(index == 3){
        $(".counter1").numberRock({
          lastNumber: $(".counter1").html()*1,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter2").numberRock({
          lastNumber: $(".counter2").html()*1,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter3").numberRock({
          lastNumber: $(".counter3").html()*1,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter4").numberRock({
          lastNumber: $(".counter4").html()*1,
          duration: 2000,
          easing: 'swing',
        });
      }

      if(index == 3 || index == 5 || index == 6){
        $('.header').addClass('black-logo')
      }else{
        $('.header').removeClass('black-logo')
      }

      if(index == 6){
        let footerH = $('footer').outerHeight()
        $('header .header-aside').css('transform','translateY(-'+ footerH +'px)')
      }else{
        $('header .header-aside').css('transform','translateY(0px)')
      }
    },
    
  });
}

// banner图轮播
function indexbannerinit(){
  var indexswiper = new Swiper(".index-banner .swiper", {
    speed:800,
    loop:true,
    // spaceBetween: 30,
    // autoplay: {
    //   delay: 6000,
    //   stopOnLastSlide: false,
    //   disableOnInteraction: false,
    // },
    on:{
      init:function(swiper){
          let slide=this.slides.eq(0);
          slide.addClass('ani-slide');
        },
      transitionStart: function(){
          for(let i=0;i<this.slides.length;i++){
            let slide=this.slides.eq(i);
            slide.removeClass('ani-slide');
        }
        },
      transitionEnd: function(){
        let slide=this.slides.eq(this.activeIndex);
        slide.addClass('ani-slide');
        },
    }
  });
}


// 产品轮播
var proswiper = new Swiper(".section1 .right-swiper .mySwiper", {
  slidesPerView: 2,
  spaceBetween: 80,
  navigation: {
    nextEl: ".section1 .right-swiper .swiper-button-next",
    prevEl: ".section1 .right-swiper .swiper-button-prev",
  },
  breakpoints: {
    1440: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 80,
    },
    768: {
      slidesPerView: 1.5,
      spaceBetween: 40,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
});

// 解决方案
var solutionBgswiper = new Swiper(".section2 .bg-swiper .swiper-container", {
  slidesPerView: 1.2,
  speed: 1000,
  spaceBetween: 50,
  allowTouchMove: false,
  breakpoints: {
    768: {
      slidesPerView: 1,
    },
  },
});
var solutionTswiper = new Swiper(".section2 .swiper-cont .swiper", {
  allowTouchMove: false,
  slidesPerView: 1,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: ".section2 .swiper-cont .swiper-button-next",
    prevEl: ".section2 .swiper-cont .swiper-button-prev",
  },
  on:{
    slideChangeTransitionStart: function(){
      solutionBgswiper.slideTo(this.activeIndex)
    },
  },
});
!(function(){
  $('.section2 .swiper-cont .right .item').eq(0).addClass('active')
})()
$('.section2 .swiper-cont .right .item').hover(function(){
  let index = $(this).index()
  if(!$(this).hasClass('active')){
    $(this).addClass('active').siblings().removeClass('active')
    solutionTswiper.slideTo(index)
    $('.section2 .swiper-cont .right .bg-trank').css('transform','translateY('+ index*100 +'%)')
  }
})


$(document).ready(function(){
  if($(window).width() > 1024){
    initFullpage()
    $('.header-aside .back-top').on('click',function(){
      indexfullpage.moveTo('page1',0)
    })
  }else{
    $('.counter').countUp();
  }
  setTimeout(indexbannerinit(),100)
})
